// Import React
import React from 'react';
// Import GraphQL
import { graphql } from 'gatsby';
// Import the GatsbySEO plugin
import { GatsbySeo } from 'gatsby-plugin-next-seo';
// Import the Gatsby Prismic plugin
import { withPreview } from 'gatsby-source-prismic';
import {
  Navbar,
  BenefitHero,
  BenefitBody,
  BenefitCTA,
  PageFooter,
  BenefitBreadcrumb
} from '../components';
// Import our styles
import '../style/style.scss';

// Export PageQuery
export const query = graphql`
  query BenefitQuery($uid: String!) {
    prismicBenefit(uid: { eq: $uid }) {
      id
      uid
      data {
        benefit_action_button_bg
        benefit_action_button_color
        benefit_action_button_text
        benefit_action_button_link {
          url
          link_type
          target
        }
        benefit_pricing_heading
        benefit_name
        benefit_list_image {
          url
        }
        benefit_type
        benefit_pricing_row {
          benefit_pricing_plan
          benefit_pricing_price
        }
        body {
          ... on PrismicBenefitBodyBenefitBodyVideo {
            id
            primary {
              benefit_body_video_embed {
                raw
              }
            }
          }
          ... on PrismicBenefitBodyBenefitBodyText {
            id
            primary {
              benefit_body_text_content {
                raw
              }
            }
          }
        }
        benefit_documents {
          benefit_doc_name
          benefit_doc_url {
            url
            link_type
            target
          }
        }
        benefit_links {
          benefit_link_name
          benefit_link_title
          benefit_link_url {
            url
            link_type
            target
          }
        }
        benefit_provider {
          uid
          document {
            ... on PrismicProviders {
              id
              data {
                provider_name
                provider_logo {
                  url
                }
                provider_bg {
                  url
                }
              }
            }
          }
        }
        benefit_support {
          benefit_support_label
          benefit_support_type
          benefit_support_value
        }
      }
    }
  }
`;

const Benefit = ({ data, pageContext }) => {
  if (!data) return null;

  const { settings } = pageContext;
  const benefit = data.prismicBenefit.data;
  const providerData = benefit.benefit_provider.document
    ? benefit.benefit_provider.document.data
    : {};
  if (!benefit || !providerData || !providerData.provider_name) {
    console.log(data);
    console.log(pageContext);
    console.log(benefit);
    console.log(providerData);
    console.log(settings.data);
    return <div>Not found</div>;
  }

  const title = `${benefit.benefit_name} - ${providerData.provider_name} | Benefits`;

  return (
    <main className="page has-parent">
      <GatsbySeo title={title} />
      <Navbar {...settings.data} />
      <BenefitHero
        company={providerData.provider_name}
        name={benefit.benefit_name}
        logo={providerData.provider_logo.url}
        background={providerData.provider_bg.url}
      />
      <BenefitBreadcrumb uid={data.prismicBenefit.uid} />
      <BenefitBody {...benefit} />
      <BenefitCTA {...settings.data} />
      <PageFooter />
    </main>
  );
};

export default withPreview(Benefit);
